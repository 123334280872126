import React, { useEffect, useState } from "react"
import _ from "lodash"
import { graphql, Link } from "gatsby"
import { TimelineLite, Power2 } from "gsap"

import Layout from "../layouts/default"
import SEO from "../components/seo"
import styled from "styled-components"

const Homepage = ({ data, pageContext }) => {
  const [pageContent, setPageContent] = useState("")
  const { edges } = data.homepage
  const { prev, next } = pageContext
  const isBrowser = typeof window !== "undefined" && window

  useEffect(() => {
    const pathName = isBrowser && window.location.pathname
    const pathNameLength = isBrowser && window.location.pathname.length
    const lastOccur = pathName.lastIndexOf("/")
    const modifiedSlug = pathName.substring(lastOccur + 1, pathNameLength)

    setPageContent(modifiedSlug)

    let tl = new TimelineLite()

    tl.from(
      ".homepage-title, .homepage-description, .homepage-content-wrapper",
      0.8,
      {
        y: 10,
        opacity: 0,
        ease: Power2.easeOut,
        stagger: {
          amount: 0.4,
        },
      }
    )

    // Image reveal animation
    const element = document.querySelectorAll(".image-cover")
    const images = document.querySelectorAll(".image-wrapper img")
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.65,
    }
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        const { target } = entry
        if (entry.intersectionRatio > 0.65) {
          target.classList.add("reveal")
        }
      })
    }, options)

    Array.prototype.forEach.call(element, el => {
      observer.observe(el)
    })
  }, [pageContent])

  return (
    <Layout isScrollable>
      <StyledHome>
        <section className="artwork-pagination">
          {prev && (
            <Link
              to={`works/${prev.slug}`}
              className="artwork-pagination--prev"
            >
              Prev
            </Link>
          )}
          {next && (
            <Link
              to={`works/${next.slug}`}
              className="artwork-pagination--next"
            >
              Next
            </Link>
          )}
        </section>
        {edges.map(({ node }) => {
          return node.content.map(page => {
            return (
              page.slug === pageContent && (
                <React.Fragment key={page.id}>
                  <SEO
                    title={page.seo.title}
                    description={page.seo.description}
                  />
                  <StyledIntro
                    className="homepage-intro"
                    isDescription={page.description}
                  >
                    <h1 className="homepage-title">{page.title}</h1>
                    {!_.isNil(page.description) && (
                      <div
                        dangerouslySetInnerHTML={{ __html: page.description }}
                        className="homepage-description"
                      />
                    )}
                  </StyledIntro>

                  {!_.isNil(page.content) && (
                    <section className="homepage-content-wrapper">
                      {page.content.map(item => {
                        if (
                          !_.isNil(item.model) &&
                          item.model.apiKey === "gallery"
                        ) {
                          return (
                            <section key={item.id} className="homepage-gallery">
                              {item.images.map((image, i) => {
                                return (
                                  <div
                                    className={`homepage-content image-wrapper`}
                                    key={i}
                                  >
                                    <img
                                      src={image.url}
                                      alt={
                                        _.isNil(image.title)
                                          ? `Image no. ${i}`
                                          : image.title
                                      }
                                    />
                                    <div className="image-cover"></div>
                                  </div>
                                )
                              })}
                            </section>
                          )
                        }

                        if (
                          !_.isNil(item.model) &&
                          item.model.apiKey === "text_block"
                        ) {
                          return (
                            <section
                              key={item.id}
                              className="homepage-content textblock-wrapper"
                            >
                              <div
                                dangerouslySetInnerHTML={{ __html: item.text }}
                                className="textblock__content"
                              />
                            </section>
                          )
                        }

                        if (
                          !_.isNil(item.model) &&
                          item.model.apiKey === "video"
                        ) {
                          return (
                            <section className="homepage-gallery video-wrapper">
                              <h4 className="video-title">{item.title}</h4>
                              <div
                                className="video-frame"
                                style={{
                                  padding: "42.53% 0 0 0",
                                  position: "relative",
                                  flexGrow: "1",
                                }}
                              >
                                <iframe
                                  src={`https://player.vimeo.com/video/${item.videoId}?title=0&byline=0&portrait=0`}
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  frameborder="0"
                                  allow="autoplay; fullscreen; picture-in-picture"
                                  allowfullscreen
                                ></iframe>
                              </div>
                            </section>
                          )
                        }

                        if (
                          !_.isNil(item.model) &&
                          item.model.apiKey === "file"
                        ) {
                          return (
                            <section
                              key={item.id}
                              className="homepage-content file-wrapper"
                            >
                              <a href={item.file.url} target="_blank">
                                {item.title}
                              </a>
                            </section>
                          )
                        }
                      })}
                    </section>
                  )}
                </React.Fragment>
              )
            )
          })
        })}
      </StyledHome>
    </Layout>
  )
}

export const homepageQuery = graphql`
  query HomepageTemplateQuery {
    homepage: allDatoCmsHomepage {
      edges {
        node {
          slug
          content {
            id
            seo {
              title
              description
            }
            slug
            title
            description
            content {
              ... on DatoCmsTextBlock {
                id
                model {
                  apiKey
                }
                text
              }
              ... on DatoCmsGallery {
                id
                model {
                  apiKey
                }
                images {
                  url
                }
              }
              ... on DatoCmsVideo {
                videoId
                model {
                  apiKey
                }
              }
              ... on DatoCmsFile {
                id
                title
                file {
                  url
                }
                model {
                  apiKey
                }
              }
            }
          }
        }
      }
    }
  }
`

const StyledHome = styled.section`
  width: 100%;
  margin: 0 auto;
  margin-top: 120px;
  font-family: "Poppins", sans-serif;

  .homepage-content-wrapper {
    margin: 0 auto;

    @media (min-width: 1024px) {
      width: calc(100% - 10rem);
    }
  }

  .homepage-intro {
    width: calc(100% - 1rem);
    flex: 1 0 30%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    margin: 0 auto;
    margin-bottom: 5rem;

    font-weight: 300;

    @media (min-width: 1024px) {
      width: calc(100% - 10rem);
      flex-flow: row nowrap;
      margin-bottom: 8rem;
    }
  }

  .homepage-title {
    flex: 1 0 40%;
    margin-bottom: 1.8rem;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 2.5px;
  }

  .homepage-description {
    flex: 1 0 40%;
    font-size: 0.9rem;
    line-height: 1.8;
    font-weight: 300;
    opacity: 0.8;
    letter-spacing: 1px;

    @media (min-width: 1024px) {
      font-size: 0.8rem;
      font-weight: 300;
    }

    p:not(:last-of-type) {
      margin-bottom: 1.2rem;
    }
  }

  .homepage-gallery {
    width: 100%;
    margin: 0 auto;
  }

  .homepage-content {
    &:nth-of-type(even) {
      margin-left: auto;
    }
  }

  .image-wrapper {
    width: 90%;
    position: relative;

    @media (min-width: 768px) {
      width: 65%;
    }

    &:not(:first-of-type) {
      margin-top: 5rem;
      margin-bottom: 5rem;

      @media (min-width: 1024px) {
        margin-top: 10rem;
        margin-bottom: 10rem;
      }
    }

    img {
      width: 100%;
      display: block;
      max-height: 80vh;
      object-fit: contain;
    }

    .image-cover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: #fff;
      transition: left 1.5s ease-in;

      &.reveal {
        left: 300%;
      }
    }
  }

  .textblock-wrapper {
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;

    font-size: 0.9rem;
    font-weight: 200;
    line-height: 1.8;
    letter-spacing: 1px;

    @media (min-width: 1024px) {
      width: 50%;
      margin-top: 15rem;
      margin-bottom: 15rem;
    }
  }

  .textblock__content {
    h1 {
      font-size: 2rem;
      font-weight: 300;
    }

    p {
      font-size: 1.2rem;
    }
  }

  .video-wrapper {
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;

    &:last-of-type {
      margin-bottom: 5rem;

      @media (min-width: 1024px) {
        margin-bottom: 10rem;
      }
    }

    @media (min-width: 1024px) {
      width: 80%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .video-title {
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 400;

    @media (min-width: 1024px) {
      margin-bottom: 0;
      flex-basis: 20%;
      text-align: left;
      margin-left: 2rem;

      &:nth-of-type(odd) {
        order: 2;
      }
    }
  }

  .video-frame {
    @media (min-width: 1024px) {
      &:nth-of-type(odd) {
        order: 1;
      }
    }
  }

  .file-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3rem;

    font-size: 0.9rem;
    font-weight: 200;
    line-height: 1.8;
    letter-spacing: 1px;

    @media (min-width: 1024px) {
      margin-bottom: 2rem;
      margin-bottom: 10rem;
    }
  }

  .homepage-pagination {
    margin: 5rem auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @media (min-width: 1024px) {
      margin: 10rem auto;
    }

    a {
      padding: 2rem 0;
      padding-bottom: 5px;
      text-decoration: none;
      border-bottom: 1px solid #000;
    }
  }
`

const StyledIntro = styled.section`
  width: 100%;
  flex: 1 0 30%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  margin: 0 auto;
  margin-bottom: 5rem;

  font-weight: 300;

  @media (min-width: 1024px) {
    width: calc(100% - 9rem);
    flex-flow: ${({ isDescription }) => isDescription && "row nowrap"};
    margin-bottom: 5rem;

    justify-content: ${({ isDescription }) =>
      !isDescription ? "center" : "flex-start"};
    text-align: ${({ isDescription }) => !isDescription && "center"};
  }

  .homepage-title {
    flex: 1 0 40%;
    margin-bottom: 1.8rem;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 2.5px;
  }
`

export default Homepage
